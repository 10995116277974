import { reactive, readonly, watch } from "vue";

import { Capacitor } from "@capacitor/core";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";

async function initializeSessionState() {
  const { useAuthStore } = await import("../hooks/useAuthStore");

  const setCheckAuthenticated = async (state) => {
    const { validateTokenRefresh } = useAuthStore();
    if (state?.refreshToken) {
      await validateTokenRefresh(
        state.token,
        state.refreshToken,
        state.user,
        state.privateKey,
        state.searchKey
      );
    }
  };
  try {
    if (
      Capacitor.getPlatform() === "ios" ||
      Capacitor.getPlatform() === "android"
    ) {
      console.log("SecureStoragePlugin.get FE");
      console.log(
        "Configured SecureStoragePlugin group:",
        SecureStoragePlugin.config
      );
      const storedToken = await SecureStoragePlugin.get({ key: "authToken" });
      await setCheckAuthenticated(JSON.parse(storedToken?.value || "{}"));
    } else {
      await setCheckAuthenticated(
        JSON.parse(window?.sessionStorage.getItem("state")) || {}
      );
    }
  } catch (e) {
    console.error("Error retrieving stored token:", e);
  }
  state.isAuthenticating = false;
}

const state = reactive({
  systemSalt: "ertwtafd7erty465etrwgedafle5qiavdcxcvbfgd",
  user: null,
  isAuthenticated: false,
  isAuthenticating: true,
  token: null,
  privateKey: null,
  publicKey: null,
  searchKey: null,
  // for temp collab. edit access
  tokenNote: null,
  accessToken: null,

  authCode: null,
  refreshToken: null,
  deviceId: null,
  labels: [],
  users: [],
  notes: [],
  roles: [],
  events: [],
  tasks: [],
  files: [],
  comments: [],
  reminders: [],
  chats: [],
  messages: {},
  collaborators: [],
  locations: [],
  checkinAlerts: [],
  contacts: [],
  settings: null,
  preferences: null,
  filters: {
    search: "",
    sort: "created_at_desc",
    toggles: {
      hasFile: null,
      hasReminder: null,
      hasTask: null,
      pinned: null,
    },
    labels: [],
    sharedWith: null,
    owner: null,
    dateRange: null,
    colors: [],
  },
  invites: [],
  userSuggestions: [],
  sharedWithSuggestions: [],
  isSidebarOpen: window.innerWidth > 767,
  // sessionState will be merged after initialization
});

window.store = state;
async function initializeStore() {
  const sessionState = await initializeSessionState();
  Object.assign(state, sessionState);
}

initializeStore(); // Initialize the session state when the app loads

watch(
  () => ({
    refreshToken: state.refreshToken,
    user: state.user,
    token: state.token,
    isAuthenticated: state.isAuthenticated,
    deviceId: state.deviceId,
    privateKey: state.privateKey,
    searchKey: state.searchKey,
  }),
  () => {
    const authData = JSON.stringify({
      user: state.user,
      token: state.token,
      refreshToken: state.refreshToken,
      isAuthenticated: state.isAuthenticated,
      deviceId: state.deviceId,
      privateKey: state.privateKey,
      searchKey: state.searchKey,
    });

    if (
      Capacitor.getPlatform() === "ios" ||
      Capacitor.getPlatform() === "android"
    ) {
      SecureStoragePlugin.set({ key: "authToken", value: authData });
    } else {
      window?.sessionStorage.setItem("state", authData);
    }
  }
);

const methods = {
  setUser(user) {
    state.user = user;
    state.isAuthenticated = !!user;
  },
  setToken(token) {
    state.token = token;
  },
  setRefreshToken(token) {
    state.refreshToken = token;
  },
  async setPrivateKey(key) {
    state.privateKey = key;
  },
  async setPublicKey(key) {
    state.publicKey = key;
  },
  clearAuth() {
    state.user = null;
    state.token = null;
    state.refreshToken = null;
    state.isAuthenticated = false;
    state.preferences = null;
    state.publicKey = null;
    state.privateKey = null;
  },
  addModel(type, data) {
    console.log("addModel", type, data);
    state[type].unshift(data);
  },
  replaceModel(type, id, updatedData) {
    const collection = state[type];
    if (collection && typeof collection[id] !== "undefined") {
      collection[id] = updatedData;
    } else {
      const index = collection?.findIndex((item) => item.id === id);
      if (index !== -1 && state[type]) {
        state[type][index] = updatedData;
      } else {
        console.log(`could not update ${type} ${index} ${collection} ${id}`);
      }
    }
  },
  updateModel(type, id, updatedData) {
    const collection = state[type];
    if (collection && typeof collection[id] !== "undefined") {
      collection[id] = collection[id]
        ? { ...collection[id], ...updatedData }
        : updatedData;
    } else {
      const index = collection?.findIndex((item) => item.id === id);
      if (index !== -1 && state[type]) {
        state[type][index] = state[type][index]
          ? { ...state[type][index], ...updatedData }
          : updatedData;
      } else {
        console.log(`could not update ${type} ${index} ${collection} ${id}`);
      }
    }
  },
  removeModel(type, id) {
    state[type] = state[type]?.filter((item) => item.id !== id);
  },
  setState(type, value) {
    state[type] = value;
  },
};

export default {
  state: readonly(state),
  mutations: methods,
};
